/* eslint-disable no-console */
import { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import { routes, privateRoutes } from 'router';
import Header from 'sections/header';
import Footer from 'sections/footer';
import { useMount, useQuery } from 'utils/hooks';
import { getCookie, setCookie } from 'utils';
import { ToastBar } from 'components/toast';

import './index.css';

const isLoggedIn = false;

function App() {
  const query = useQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const accountId = query.get('accountId');

  const activeUser = getCookie('activeAccountId');

  useMount(() => {
    if (process.env.NODE_ENV === 'production') {
      // List of console methods to override
      const methodsToOverride = ['debug', 'info', 'warn', 'error', 'dir', 'trace', 'group', 'groupEnd'];

      methodsToOverride.forEach(method => {
        if (console[method]) {
          console[method] = () => {};
        }
      });
    }
  });

  useEffect(() => {
    if (accountId && !activeUser) {
      setCookie('activeAccountId', accountId, 150);
    }
  }, [accountId, activeUser]);

  useMount(() => {
    if (!isLoggedIn && !routes.find(({ path }) => path === pathname)) {
      navigate('/signup');
    }
  });

  return (
    <div className="root">
      <Header />
      <main>
        <Routes>
          {(isLoggedIn ? privateRoutes : routes).map(({ Component, path }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
      </main>
      <Footer />
      <ToastBar />
    </div>
  );
}

export default App;
